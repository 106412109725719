import React from "react";
import Icon from "@ant-design/icons";

const TwiterXSvg = () => (
  <svg viewBox="0 0 32 32">
    <path stroke="currentColor" d="M24.325 3h4.411l-9.636 11.013 11.336 14.987h-8.876l-6.952-9.089-7.955 9.089h-4.413l10.307-11.78-10.875-14.22h9.101l6.284 8.308zM22.777 26.36h2.444l-15.776-20.859h-2.623z"></path>
  </svg>
);

const TwiterXIcon = (props: any) => (
  <Icon component={TwiterXSvg} {...props} />
);

export default TwiterXIcon;
